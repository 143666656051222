// import useFetchDrop from './useFetchDrop.js';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import MintingLayout from "../MintingLayout/MintingLayout.js";
function Drop({ slug, dropPage }){

    const GET_DROPDATA = gql `query GetDropData($slug: String!) { 
        dropCollection(where:{ slug: $slug }){
              items{
                name,
                slug,
                metaTitle,
                metaDescription,
                launchDate,
                smartContract,
                dropWallet,
                dropStage,
                description,
                shortDescription,
                price,
                currency,
                placeHolderImage,
                mintingBackgroundImage,
                mintingPageMessage,
                mintingHasntStartedMessage,
                mintingClosedMessage,
                mintingLoginFailureMessage,
                allowedList,
                allowedListRejectionMessage,
                enrollmentGatedMessage,
                enrollmentSuccessMessage,
                enrollmentDirectionsMessage,
                enrollmentClosedMessage,
                formId,
                emailFieldId,
                walletFieldId,
                infoPageContent
              }
            }
          }`;
    const  { loading, error, data } = useQuery( GET_DROPDATA, { 
        variables: { "slug": slug }, 
    });

    if (loading) return '';
    if (error) return `Error! ${error.message}`;
    
    const DropData    = data.dropCollection.items;
  
    console.log(JSON.stringify(DropData[0].name));
    
    return(
        <MintingLayout Drop={DropData[0]} dropPage={dropPage}></MintingLayout>
    )



    // rout to /DropName
    //         /DropName/Minting
                    // DropName/Minting/View-Collection
    //         /DropName/Allowed-List


}
export default Drop;
// import { useEffect, useCallback} from "react";
import { useEffect, useState} from "react";
import CollectionItem from "../CollectionItem/CollectionItem.js";
import {
  useOwnedNFTs
} from "@thirdweb-dev/react";

const MintingCollection = ({address,  parentCallback, numberNftOwned, nftDrop, viewNFTsHandleCallback, ContractID, hasFetched, fetchDataCallback, numberNFTCallback, fetchData}) => {
    
  const { data: ownedNFTs } = useOwnedNFTs(nftDrop, address);
  const [ownersData, setOwnersData] = useState(false);

    useEffect(() => {
         
        if (fetchData && !hasFetched) {
           
            parentCallback(true);
            viewNFTsHandleCallback(true);
        }
        
      }, [fetchData, viewNFTsHandleCallback, hasFetched, parentCallback])


      useEffect(() => {
        

        if(address && ownedNFTs ){
          if(ownedNFTs.length !== numberNftOwned){
            console.log(ownedNFTs.length +' number owned' + numberNftOwned);
            numberNFTCallback(ownedNFTs.length);

            fetchDataCallback(ownedNFTs)
            setOwnersData(ownedNFTs);
          }
          
        }
       
      }, [ address, ownedNFTs, numberNFTCallback, numberNftOwned, fetchDataCallback])
     
      const openSeaBase =  "https://opensea.io/assets/ethereum/"+ContractID+"/";
      // const testOpenSeaBase =  "https://testnets.opensea.io/assets/rinkeby/"+ContractID+"/";
 
       if(ownersData ){
          
            if(ownersData.length !== 0 ){
             
                  return(
                    <div className="collection__grid collection__padding">
                        {ownersData.slice(0).reverse().map( (assets, nftAssetIndex) => ( <CollectionItem NftImage={assets.metadata.image} nftName={assets.metadata.name}  permalink={openSeaBase+(assets.metadata.edition - 1)}  tokenid={(assets.metadata.edition - 1)} key={nftAssetIndex}></CollectionItem>))}
                    </div>
                    
                );
        
            }
        }

};
export default MintingCollection;
import GoogleForm from "../GoogleForm/GoogleForm.js";


const Enrollment = ({collectionPlaceHolder, connectWithMetamask, connectWithWalletConnect, disconnect, connectWithCoinbaseWallet, address, Drop, form, walletFieldID, emailFieldID}) => {
    

        return (

            <div className="page">
            <div className="detail">
                <div className="detail__image">
                    <div  className="detail__align">
                        <img alt="Artist Danny Roberts Genesis NFT Collection" src={collectionPlaceHolder}></img>
                    </div>
                </div>
                <div className="mintingBar detail__data">
                    <div className="detail__align">
                        <GoogleForm walletID={address}  connectWithWalletConnect={connectWithWalletConnect} disconnect={disconnect} connectWithCoinbaseWallet={connectWithCoinbaseWallet} connectWithMetamask={connectWithMetamask}  dropData={Drop} formIDs={ form} walletFieldIDs={walletFieldID} emailFieldIDs={emailFieldID}></GoogleForm>
                    </div>
                </div>
            </div>
            </div>
        );

}
export default Enrollment;
import {
  ChainId,
  useClaimedNFTSupply,
  useContractMetadata,
  useNetwork,
  useNFTDrop,
  useUnclaimedNFTSupply,
  useOwnedNFTs,
  useMetamask,
  useWalletConnect,
  useCoinbaseWallet
} from "@thirdweb-dev/react";

// import {
//   PaperCheckout
// } from "@paperxyz/react-client-sdk";

import { useNetworkMismatch } from "@thirdweb-dev/react";
import { useState, useEffect } from "react";
import "./Minting.css";
import MintingCollection from "../MintingCollection/MintingCollection.js";
import MintingSidebar from "../MintingSidebar/MintingSidebar.js";

// import { Parallax } from 'react-scroll-parallax';


const Minting = ({address, ContractID, collectionPlaceHolder, setHasFetched, hasFetched, collectionViewBackground, dropData, dropPage}) => {
  const CHECKOUTID = process.env.REACT_APP_CHECKOUT_ID;
  const myNftDropContractAddress = dropData.smartContract;
  const nftDrop = useNFTDrop(myNftDropContractAddress);
  
  const [viewNFT, setViewNFT] = useState(false);
  const isOnWrongNetwork = useNetworkMismatch();
  const [, switchNetwork] = useNetwork();
  const [claiming, setClaiming] = useState(false);
  const { data: ownedNFTs } = useOwnedNFTs(nftDrop, address);


const connectWithMetamask = useMetamask();
const connectWithWalletConnect = useWalletConnect();
const connectWithCoinbaseWallet = useCoinbaseWallet();
 
  // Load contract metadata
  const {data: contractMetadata } = useContractMetadata(myNftDropContractAddress);
  

  // Load claimed supply and unclaimed supply
  const { data: unclaimedSupply } = useUnclaimedNFTSupply(nftDrop);
  const { data: claimedSupply } = useClaimedNFTSupply(nftDrop);


  const nftPrice = dropData.price;
  const currency = dropData.currency;
  const startText = "Mint - "+nftPrice+" "+currency;
  const [nftButtonText, setNftButtonText] = useState(startText);
  const [nftquanity, setNFTQuanity] = useState(1);
  const [fetchData, setFetchData] = useState(false);
  const [numberNftOwned, setNumberNftOwned] = useState(false);
  // const [cardCheckout, setCardCheckout] = useState(false);
  const [ethCheckout, setEthCheckout] = useState(false);

  useEffect(() => {

    if(!numberNftOwned && address && ownedNFTs){
      console.log(ownedNFTs.length);
      // console.log(JSON.stringify(ownedNFTs));
      setNumberNftOwned(ownedNFTs.length);
    
    }
    
  }, [numberNftOwned, address, ownedNFTs])
    

 

  // openSeaApi
  const fetchHandleCallback = (childData) =>{
    if(!hasFetched){
      setHasFetched(childData);
    }
    if(childData === false && hasFetched !== false){
      setHasFetched(childData);
    }
  }

  const numberNFTHandleCallback = (data) =>{ setNumberNftOwned(data);}
  const FetchDataHandleCallback = (data) =>{  setFetchData(data);}
  const viewNFTsHandleCallback = (data) =>{  setViewNFT(data);}

  const paywithethCallback = () => { setEthCheckout(true);}
  // const paywithCardCallback = () => { setCardCheckout(true);
  //   //
  // }
  
  const onPayWithCardTransferSuccess = (TransferSuccessResult) => {
    setTimeout(() => {
      console.log(JSON.stringify(TransferSuccessResult));
      setFetchData(false);
      setClaiming(false);
      fetchHandleCallback(false);
      setViewNFT(true);
    }, 100)
};

  // Function to View Minted NFTs
  function continureMinting(){
      if(viewNFT){
       
        // window.location.href =  "/"+dropData.slug+"/Minting";
        setViewNFT(false)
        setNFTQuanity(1)
        setNumberNftOwned(false)
        setFetchData(false);
      
      }
  }

// updates Total
  function numberNFTsHandle(e) {
    let input = e.target;
    console.log('Value: '+input.value);
    console.log('Min:'+input.min);
    console.log('Max:'+input.max);
    let value = Math.max(Number(input.min), Math.min(Number(input.max), Number(input.value)));
		let totalPrice = nftPrice * value;
    let newButtonText = "Mint - "+totalPrice+" "+currency;
    setNftButtonText(newButtonText);
    setNFTQuanity(value);
   
	}



  // Loading state while we fetch the metadata
  if (!nftDrop || !contractMetadata) {
    return (
      <MintingSidebar 
      image={collectionPlaceHolder} 
      title={dropData.name} 
      message={'Loading...'} 
      type = 'basic' 
     /> ) 
  }

   

     
   


  async function mint() {
    // Make sure the user has their wallet connected.
    if (!address) {
      connectWithMetamask();
      return;
    }
 
    // Make sure the user is on the correct network (same network as your NFT Drop is).
    if (isOnWrongNetwork) {
      switchNetwork && switchNetwork(ChainId.Rinkeby);
       return (

            <MintingSidebar 
            image={collectionPlaceHolder} 
            title={dropData.name} 
            message={'Wrong Network Switching Networks...'} 
            type = 'basic' 
          />
      )
  
    }

    setClaiming(true);

    try {
      const minted = await nftDrop?.claim(nftquanity);
      console.log(minted);
      // alert(`Successfully minted NFT!`);

    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
     
      
          setTimeout(() => {
            
            setFetchData(false);
            setClaiming(false);
            fetchHandleCallback(false);
            setViewNFT(true);
          }, 100)
      
    }
  }
  const myStyle={
    backgroundImage: "url('"+collectionViewBackground+"')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

 
  

//if there isn't a wallet connected, display our connect MetaMask button
// console.log(viewNFT);
  if(viewNFT ){  
    
    

    return (

        <div className="page">
          <div className="detail">
            <div className="detail__image collections" style={myStyle}>
                <div className="yourCollectionTitle">
                  <h1 className="collection__title collection__padding"><span>Meet Your NFTs</span>
                  <span className="clickToView">Click on Images to View on OpenSea</span></h1>
                </div>
                <div  className="collection__main --small">

                <MintingCollection address={address} parentCallback={fetchHandleCallback} numberNftOwned={numberNftOwned} nftDrop={nftDrop} viewNFTsHandleCallback={viewNFTsHandleCallback} numberNFTCallback={numberNFTHandleCallback} fetchDataCallback={FetchDataHandleCallback} ContractID={ContractID} hasFetched={hasFetched} fetchData={fetchData}></MintingCollection>
              
                </div>
            </div>
            <div className="mintingBar detail__data">
                <div className="detail__align">
                <div className="container">
            <span className="iandatext external">w</span>
            <h1>{dropData.name}</h1>
            
            <div className="mintAreaRight">

           
                    {numberNftOwned && unclaimedSupply ? (
                       <p>You currently own {numberNftOwned} / { claimedSupply?.toNumber() + unclaimedSupply?.toNumber()} NFTs from this collection!</p>
                    ) : (
                      // Show loading state if we're still loading the supply
                      <p>Loading...</p>
                    )}
                  </div>
                  <div className="mintAreaLeft">
                    <div className="mintingRow">
                      <button className="btn chakra-button css-xl8nff" onClick={continureMinting} >
                      Continue Minting
                       </button>
                    </div>
                  </div>
          </div>
            </div>
          </div>
      </div>
      </div> 
      );

  }

// only Allowed People
  if(address) {
    if(!dropData.allowedList.some(item => item.walletID === address) && dropData.dropStage === 'Allowed Minting'){
      
       return(
              <MintingSidebar 
              image={collectionPlaceHolder} 
              title={dropData.name} 
              message={dropData.allowedListRejectionMessage} 
              type = 'basic' 
            />
       )
      }
    }

    if(ethCheckout || address){
      return(
          <div className="page">
            <div className="detail">
              <div className="detail__image collections" >
                 
               
                    <div  className="detail__align">
                        <img alt="dropData.name" src={collectionPlaceHolder}></img>
                    </div>
                 
              </div>
              <div className="mintingBar detail__data">
                  <div className="detail__align">
                  <div className="container">
              <span className="iandatext external">w</span>
              <h1>{dropData.name}</h1>
              
              <div className="mintAreaRight">
                      {claimedSupply && unclaimedSupply ? (
                        <p>
                          {/* Claimed supply so far */}
                          <b>{claimedSupply?.toNumber()}</b>
                          {" / "}
                          {
                            // Add unclaimed and claimed supply to get the total supply
                            claimedSupply?.toNumber() + unclaimedSupply?.toNumber()
                          }
                          { "  claimed"}
                        </p>
                      ) : (
                        // Show loading state if we're still loading the supply
                        <p>Loading...</p>
                      )}
                    </div>
                    {address ? (
                    <div className="mintingRow">
                      <input inputMode="numeric" type="text" pattern="[0-9]*(.[0-9]+)?" role="spinbutton" onChange={ e => numberNFTsHandle(e)} min="1" max={unclaimedSupply?.toNumber()} autoCorrect="off" className="chakra-numberinput__field css-mp6ry6" value={nftquanity}/>
                      <button className={claiming ? "btn chakra-button css-xl8nff currentClaiming" : "btn chakra-button css-xl8nff"} onClick={mint} disabled={claiming}>
                            {claiming ? "Claiming..." : nftButtonText }
                      </button>
                    </div>
                    ):( <div className="threeOptionsRow">
                                <button className="btn threeOptions" onClick={connectWithMetamask}><span className="chakra-button__icon css-1wh2kri"><img alt="MetaMask" src="https://thirdweb.com/logos/metamask-fox.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>MetaMask</button>
                                <button className="btn threeOptions" onClick={connectWithWalletConnect}><span className="chakra-button__icon css-1wh2kri"><img alt="WalletConnect" src="https://thirdweb.com/logos/walletconnect-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>WalletConnect</button>
                                <button className="btn threeOptions" onClick={connectWithCoinbaseWallet}><span className="chakra-button__icon css-1wh2kri"><img alt="Coinbase Wallet" src="https://thirdweb.com/logos/coinbase-wallet-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>Coinbase Wallet</button>
                      </div>
                      )}
                  </div>
            </div>
              </div>
            </div>
        </div>
      

      )

    }

  return (

    <div className="page">
      <div className="detail">
        <div className="detail__image collections" >
           
         
              <div  className="detail__align">
                  <img alt="dropData.name" src={collectionPlaceHolder}></img>
              </div>
           
        </div>
        <div className="mintingBar detail__data">
            <div className="detail__align">
            <div className="container">
        <span className="iandatext external">w</span>
        <h1>{dropData.name}</h1>
        
        <div className="mintAreaRight">
                {claimedSupply && unclaimedSupply ? (
                  <p>
                    {/* Claimed supply so far */}
                    <b>{claimedSupply?.toNumber()}</b>
                    {" / "}
                    {
                      // Add unclaimed and claimed supply to get the total supply
                      claimedSupply?.toNumber() + unclaimedSupply?.toNumber()
                    }
                    { "  claimed"}
                  </p>
                ) : (
                  // Show loading state if we're still loading the supply
                  <p>Loading...</p>
                )}
              </div>
              <div className="mintAreaLeft">
                
              
                    <div className="mintingRow paychoicerow">
                      <button className="btn paychoice" onClick={paywithethCallback}>Pay with ETH</button>
                    <button className="btn btnMargin paychoice" onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://paper.xyz/checkout/0bc649c1-53ba-4347-85a1-68d89f64d910';
                        }}>Pay Credit Card</button>
                    </div>
                  
          
              </div>
      </div>
        </div>
      </div>
  </div>
  </div> 
  );
}


export default Minting;

const NonMetaMask = () => {

    return (
        <iframe
            src="https://cloudflare-ipfs.com/ipfs/QmZ3UzARChNBBVdqcbibQ63nvtePkRfnyieb89sXTRMLxE/nft-drop.html?contract=0xDf59021cF11eb19a0bb2df21c795673AfF2d3e96&chainId=1&theme=dark"
            width="600px"
            height="600px"
            
            frameborder="0"
            title="MintingFrame"
        ></iframe>
    )

}
export default NonMetaMask;
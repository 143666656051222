
const CollectionItem = ({NftImage, nftName, permalink, tokenid}) => {

    return (
        <div className="collection__item" data-id={tokenid}>
        {/* <div className="collection__item" data-id={tokenid} > */}
            <div className="collection__item__image">
                <a href={permalink} >
                    <img data-src={NftImage} decoding="async" crossOrigin="anonymous" alt={nftName} src={NftImage}/>
                </a>
            </div> 
            <div className="collection__item__meta">
                <a href={permalink} >
                    <span>{nftName}</span>
                </a>
            </div>
        {/* </div> */}
        </div>
   );
};
export default CollectionItem;

import React, {useState, useRef} from 'react'
import "./GoogleForm.css";

const GoogleForm = ({walletID, connectWithMetamask,connectWithCoinbaseWallet, disconnect, connectWithWalletConnect, dropData,formIDs,walletFieldIDs,emailFieldID}) => {
    
    // Pass in the URL From the Signup Form Data. No Hard Coding.


    const emailRef = useRef(null)
    const [iframeSource, setIframeSource] = useState(false)
    const [loading, setLoading] = useState(false)
    const [finished, setFinished] = useState(false)
    

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
        const formID = dropData.formId;const scriptUrl = "https://docs.google.com/forms/d/e/"+formID+"/formResponse?";
        const scriptEnd = "&submit=Submit";
        const walletFieldID = dropData.walletFieldId;
        const emailFieldID  = dropData.emailFieldId;
        const cleanEmail =  encodeURIComponent(emailRef.current.value);

        const submitURL = (scriptUrl + walletFieldID + '=' + walletID + '&' + emailFieldID + '=' + cleanEmail + scriptEnd);
        console.log(cleanEmail);
        setIframeSource(submitURL);

        setTimeout(() => {
            setIframeSource(false);
            setFinished(true)
        }, 3000)
    }
     
    // Not Logged in
    if (!walletID) {
        return (
     
          <div className="container">
            <span className="iandatext external">w</span>
            <h1>{dropData.name}</h1>
            <p>{dropData.enrollmentGatedMessage}</p>
            <button className="btn threeOptions" onClick={connectWithMetamask}><span className="chakra-button__icon css-1wh2kri"><img alt="MetaMask" src="https://thirdweb.com/logos/metamask-fox.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>MetaMask</button>
            <button className="btn threeOptions" onClick={connectWithWalletConnect}><span className="chakra-button__icon css-1wh2kri"><img alt="WalletConnect" src="https://thirdweb.com/logos/walletconnect-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>WalletConnect</button>
            <button className="btn threeOptions" onClick={connectWithCoinbaseWallet}><span className="chakra-button__icon css-1wh2kri"><img alt="Coinbase Wallet" src="https://thirdweb.com/logos/coinbase-wallet-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>Coinbase Wallet</button>
          </div>
       
         
        );
      }
      // Success message
      if (finished) {
        return (
    
          <div className="container">
            <span className="iandatext external">w</span>
            <h1>{dropData.name}</h1>
            <p>{dropData.enrollmentSuccessMessage}</p>
            <button className="btn threeOptions" onClick={disconnect}>Disconnect</button>
          </div>
        );
      }

       // Enrollment Closed
      if (dropData.dropStage !== 'Open Enrollment') {
        return (
    
          <div className="container">
            <span className="iandatext external">w</span>
            <h1>{dropData.name}</h1>
            <p>{dropData.enrollmentClosedMessage}</p>
          </div>
        );
      }

  // Enrollment Form
  return (
        
    <div className="container">
        <span className="iandatext external">w</span>
        <h1>{dropData.name}</h1>
        <p>{dropData.enrollmentDirectionsMessage}</p>
        <form onSubmit={handleSubmit} name="google-sheet">
        <div className="input-style">
            <input type="email" name="emailAddress" placeholder='Email *' ref={emailRef} />
        </div>
        <div className="input-style hidden">
            <input type="text" readOnly name="walletID" value={walletID}/>
        </div>
        <div className="input-style">

            <button type="submit" className="btn joinbtn"> {loading ? "Loading..." : "Join Allowed List"}</button>
        </div> 
        </form>
        { iframeSource?  <iframe id="iframe" title="gform" className="thehidden" src={iframeSource}></iframe> : false }
    </div>
    
  )
}

export default GoogleForm;
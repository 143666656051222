import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import './polyfill';
import "./index.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import {
  BrowserRouter as Router,
} from 'react-router-dom';

// This is the chainId your dApp will work on.
const activeChainId = ChainId.Mainnet;
const container = document.getElementById("root");
const root = createRoot(container);
const SPACE_ID = process.env.REACT_APP_CONTENTFUL_ID;
const CONTENFUL_API_KEY = process.env.REACT_APP_CONTENTFUL_KEY;
const CONTENTFUL_URL = 'https://graphql.contentful.com/content/v1/spaces/'+SPACE_ID+'/?access_token='+CONTENFUL_API_KEY;

const client = new ApolloClient({
  uri: CONTENTFUL_URL,
  cache: new InMemoryCache()
});

root.render(
  <ApolloProvider client={client} >
    <React.StrictMode>
        <ThirdwebProvider desiredChainId={activeChainId}>
          <Router>
              <App />
          </Router>
    
        </ThirdwebProvider>
    </React.StrictMode>
    </ApolloProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

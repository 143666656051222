const MintingSidebar = ({image, title, message, type = 'basic', buttonText= null, buttonHand = null}) =>{
// types, basic, button
    if(type === 'button'){

        return(
            <div className="page">
            <div className="detail">
              <div className="detail__image">
                  <div  className="detail__align">
                    <img alt={title} src={image}></img>
                  </div>
              </div>
              <div className="mintingBar detail__data">
                  <div className="detail__align">
                  <div className="container">
                        <span className="iandatext external">w</span>
                        <h1>"{title}"</h1>
                        <p>{message}</p>
                        <button className="btn" onClick={buttonHand}>{buttonText}</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
    
        )

    }
    return(
        <div className="page">
        <div className="detail">
          <div className="detail__image">
              <div  className="detail__align">
                <img alt={title} src={image}></img>
              </div>
          </div>
          <div className="mintingBar detail__data">
              <div className="detail__align">
              <div className="container">
                    <span className="iandatext external">w</span>
                    <h1>"{title}"</h1>
                    <p>{message}</p>
                  </div>
              </div>
          </div>
        </div>
      </div>

    )


}
export default MintingSidebar;
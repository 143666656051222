// import { useState, useEffect } from "react";
import Minting from "../Minting/Minting.js";
import Enrollment from "../Enrollment/Enrollment.js";
import MintingSidebar from "../MintingSidebar/MintingSidebar.js";
import { useState } from "react";
import "./MintingLayout.css";
import {
  useAddress,
  useWalletConnect,
  useMetamask,
  useDisconnect,
  useNetworkMismatch,
  ChainId,
  useNetwork,
  useCoinbaseWallet,

} from "@thirdweb-dev/react";


const MintingLayout = ({Drop, dropPage}) => {

const [
  {
  data: { chain },
  loading,
}, switchNetwork] = useNetwork();
const address = useAddress();
const connectWithMetamask = useMetamask();
const connectWithWalletConnect = useWalletConnect();
const connectWithCoinbaseWallet = useCoinbaseWallet();
const disconnect = useDisconnect();
const networkMismatched = useNetworkMismatch(); 


const collectionStage = Drop.dropStage;
// const collectionStage = "Minting"
const ContractID = Drop.smartContract;
const collectionPlaceHolder = Drop.placeHolderImage;
const collectionViewBackground = Drop.mintingBackgroundImage;

const [hasFetched, setHasFetched] = useState(false);
const switchHandler = () => {
  switchNetwork && switchNetwork(ChainId.Mainnet)
}

if( Drop !== null){
  // console.log('variable test'+Drop.name+' walletFieldIDs:'+Drop.emailFieldId+' '+Drop.formId );
      // Enrollment

   
      if((collectionStage === 'Open Enrollment' && dropPage !== 'Minting') || (dropPage === 'Allowed-List')){
        return (
          <Enrollment collectionPlaceHolder={collectionPlaceHolder} disconnect={disconnect} connectWithWalletConnect={connectWithWalletConnect} connectWithMetamask={connectWithMetamask} connectWithCoinbaseWallet={connectWithCoinbaseWallet} address={address} Drop={Drop}  form={ Drop.formId} walletFieldID={Drop.walletFieldID} emailFieldID={Drop.emailFieldId}/> 
        );
    }
  
    if (networkMismatched) {
      let netMessage = loading ? ("Connecting...") : 
                       chain ?   ("Your currently the on the Wrong Network ("+chain.name+"), Click Below to switch to the correct one.") : ("")
              return(
                   <MintingSidebar 
                                  image={collectionPlaceHolder} 
                                  title={Drop.name} 
                                  message={netMessage} 
                                  type = 'button' 
                                  buttonText={"Switch Networks"} 
                                  buttonHand={switchHandler}/> );

      }else{
        if(collectionStage ==='Complete'){
          return (
                <MintingSidebar 
                image={collectionPlaceHolder} 
                title={Drop.name} 
                message={Drop.mintingClosedMessage} 
                type = 'basic' 
                />
          );
        }
     

        // Minting Hasnt Started
          if((collectionStage === 'Pre Release') || (collectionStage === 'Open Enrollment' && dropPage === 'Minting')){
            return (
              <MintingSidebar 
              image={collectionPlaceHolder} 
              title={Drop.name} 
              message={Drop.mintingHasntStartedMessage} 
              type = 'basic' 
              />

            );
        }

       

          console.log('hasFetched:'+hasFetched);
            
          if(hasFetched && address){
              return (

                    <Minting address={address}
                        connectWithMetamask={connectWithMetamask}
                        connectWithWalletConnect={connectWithWalletConnect}
                        networkMismatched={networkMismatched}
                        ContractID={ContractID}
                        collectionPlaceHolder={collectionPlaceHolder}
                        hasFetched={hasFetched}
                        setHasFetched={setHasFetched}
                        collectionViewBackground={collectionViewBackground}
                        dropData={Drop}
                        dropPage={dropPage}
                    />

              );

            }
            // isViewingCollection
            
            if (address) {
              return (
                <Minting address={address}
                connectWithMetamask={connectWithMetamask}
                connectWithWalletConnect={connectWithWalletConnect}
                networkMismatched={networkMismatched}
                ContractID={ContractID}
                collectionPlaceHolder={collectionPlaceHolder}
                hasFetched={hasFetched}
                setHasFetched={setHasFetched}
                collectionViewBackground={collectionViewBackground}
                dropData={Drop}
                dropPage={dropPage}
                />

              );
            
            }
          
            return(
              <Minting address={address}
                connectWithMetamask={connectWithMetamask}
                connectWithWalletConnect={connectWithWalletConnect}
                networkMismatched={networkMismatched}
                ContractID={ContractID}
                collectionPlaceHolder={collectionPlaceHolder}
                hasFetched={hasFetched}
                setHasFetched={setHasFetched}
                collectionViewBackground={collectionViewBackground}
                dropData={Drop}
                dropPage={dropPage}
                />
            );

            // return (
                  
            //         <div className="page">
            //         <div className="detail">
            //           <div className="detail__image">
            //               <div  className="detail__align">
            //                 <img alt={Drop.name} src={collectionPlaceHolder}></img>
            //               </div>
            //           </div>
            //           <div className="mintingBar detail__data">
            //               <div className="detail__align">
            //               <div className="container">
            //                     <span className="iandatext external">w</span>
            //                     <h1>"{Drop.name}"</h1>
            //                     <p>{Drop.mintingPageMessage}</p>
            //                     <button className="btn threeOptions" onClick={connectWithMetamask}><span className="chakra-button__icon css-1wh2kri"><img alt="MetaMask" src="https://thirdweb.com/logos/metamask-fox.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>MetaMask</button>
            //                     <button className="btn threeOptions" onClick={connectWithWalletConnect}><span className="chakra-button__icon css-1wh2kri"><img alt="WalletConnect" src="https://thirdweb.com/logos/walletconnect-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>WalletConnect</button>
            //                     <button className="btn threeOptions" onClick={connectWithCoinbaseWallet}><span className="chakra-button__icon css-1wh2kri"><img alt="Coinbase Wallet" src="https://thirdweb.com/logos/coinbase-wallet-logo.svg" className="chakra-image css-e8luex" aria-hidden="true" focusable="false"></img></span>Coinbase Wallet</button>
            //                   </div>
            //               </div>
            //           </div>
            //         </div>
            //       </div>

            // );
          }
    }else{
      return(<div className="page">
                  <div className="detail">
                  </div>
             </div>  )
    }

};

export default MintingLayout;
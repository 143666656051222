import React,{useState, useEffect} from 'react';
import "./App.css";
import Drop from "./Components/Drop/Drop.js";
import NonMetaMask from "./Components/NonMetaMask/NonMetaMask.js";
import {
  Switch,
  Route,
  useLocation,
  useHistory
} from 'react-router-dom';
import usePrevLocation from './Components/Hooks/usePrevLocation.js';
import {motion} from "framer-motion";


const App = () => {

  let history = useHistory();

  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const [hasAPageLoaded, sethasAPageLoaded] = useState(false);

  console.log(history);
  console.log("location: "+location.pathname+ "   -  prevLocation:"+prevLocation.pathname);

  useEffect(()=>{
    prevLocation.pathname !== location.pathname &&   sethasAPageLoaded(true);
    },[prevLocation.pathname, location.pathname])

  let currentPath = '';
  let dropPagePath = '';
 let locationString = location.pathname.toString();

  if(locationString.includes("Minting")){
    let splitPath = locationString.replace('/', '');
        currentPath = splitPath.replace('/Minting', '');
        currentPath = currentPath.replace(/\s/g, '');
        dropPagePath = "Minting";
  }else if(locationString.includes("Allowed-List")){
    let splitPath = locationString.replace('/', '');
    currentPath = splitPath.replace('/Allowed-List', '');
    currentPath = currentPath.replace(/\s/g, '');
    dropPagePath = "Allowed-List";
  }else{
    let splitPath = locationString.replace('/', '');
    currentPath = splitPath.replace('/', '');
    currentPath = currentPath.replace(/\s/g, '');
    dropPagePath = "CollectionHome";
  }

  console.log("dropPagePath: "+currentPath+ "   -   dropPagePath:"+ dropPagePath);
  // HardCoding Remove Later.
  const pageSlug = '15-years-later';
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1
      }
    }
  }

   return (
    <motion.div className="ia__layout" 
                variants={container}
                initial="hidden"
                animate="show">
      <Switch >
          <Route exact path="/" >
                <Drop slug={pageSlug}  dropPage={ dropPagePath}></Drop>
          </Route>
          <Route exact path="/:id" >
                <Drop slug={currentPath} dropPage={ dropPagePath}></Drop>
          </Route>
          <Route exact path="/:id/Minting" >
                <Drop slug={currentPath} dropPage={ dropPagePath}></Drop>
          </Route>
          <Route exact path="/:id/non-metamask" >
                <NonMetaMask ></NonMetaMask >
          </Route>
      </Switch>
    </motion.div>
  );
}

export default App;
